import React from 'react';
import {
  IonContent,
  IonHeader, IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import './barcodes.css';
import {codeSlashOutline} from "ionicons/icons";

type BarcodesPageProps = RouteComponentProps;

const Barcodes: React.FC<BarcodesPageProps> = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle class="ion-text-center">Barcodes</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <div className={'work-in-progress'}><IonIcon style={{fontSize: '44px'}} icon={codeSlashOutline} /><br /> Page en cours de constructions...</div>
      </IonContent>
    </IonPage>
  );
};

export default Barcodes;
