import React, { useState } from 'react';
import { IonIcon } from '@ionic/react';
import {
  beerOutline,
  cardOutline,
  fastFoodOutline,
  globeOutline,
  gridOutline,
  heartOutline,
  phonePortrait,
  pricetagsOutline,
  ticketOutline,
  chevronUpOutline,
  chevronDownOutline,
} from 'ionicons/icons';
import './TransactionDetails.css';
import { get } from '../../services/auth.services';
import { setAuthenticated } from '../../redux/auth.redux';
import Loading from '../Loading/Loading';
import { useDispatch } from 'react-redux';

interface transactionProps {
  transaction: any;
  currentWallet: any;
}

const transactionType: any = {
  accreditation: heartOutline,
  backoffice: globeOutline,
  import: globeOutline,
  desk: cardOutline,
  eshop: globeOutline,
  store: pricetagsOutline,
  udesk: cardOutline,
  mobile: phonePortrait,
  bar: beerOutline,
  food: fastFoodOutline,
  other: gridOutline,
  ticketing: ticketOutline,
};

const TransactionDetails: React.FC<transactionProps> = ({
  transaction,
  currentWallet,
}) => {
  const [basketItems, setBasketItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const dispatch = useDispatch();

  function handleClickTransaction() {
    if (!isCollapseOpen) {
      setLoading(true);
      get('/baskets/' + transaction.basket.id + '/items')
        .then((items) => {
          setBasketItems(items.data);
          setIsCollapseOpen(true);
        })
        .catch((error) => {
          if (error.status === 401) {
            dispatch(setAuthenticated(false));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setBasketItems([]);
      setIsCollapseOpen(false);
    }
  }

  return (
    <div className="cursor transaction" onClick={handleClickTransaction}>
      <div style={{ display: 'flex' }}>
        <div className="symbol">
          <IonIcon icon={transactionType[transaction.basket.pos.type]} />
        </div>
        <div className="center">
          <div className="pos_name">{transaction.basket.pos.name}</div>
          <div className="hour">
            {new Date(transaction.createdAtUTC).toLocaleString()}
          </div>
        </div>
        <div className={'amount' + ' ' + transaction.type}>
          {transaction.value} {currentWallet?.wallet.unitSymbole}
        </div>
        <div className="chevron">
          <IonIcon
            icon={isCollapseOpen ? chevronUpOutline : chevronDownOutline}
          />
        </div>
      </div>
      {basketItems && basketItems.length > 0 && (
        <div className="details">
          {basketItems.map((item: any, index) => (
            <div className={'item-element'} key={index}>
              <div>
                <div>{item?.formule?.marketingName}</div>
                <div>
                  {item?.formule?.cashlessValue}{' '}
                  {currentWallet?.wallet.unitSymbole}
                </div>
              </div>
              <div>x{item.quantity}</div>
            </div>
          ))}
        </div>
      )}
      <Loading isLoading={loading} />
    </div>
  );
};

export default TransactionDetails;
