import React, { useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonIcon,
} from '@ionic/react';
import { cameraOutline } from 'ionicons/icons';
import SupportDetailsModal from '../../components/SupportDetailsModal/SupportDetailsModal';
import Loading from '../../components/Loading/Loading';
import { get } from '../../services/auth.services';
import { setAuthenticated } from '../../redux/auth.redux';
import { useDispatch } from 'react-redux';
import { QrScanner } from '@yudiel/react-qr-scanner';
import './Nfc.css';

const Nfc: React.FC = () => {
  const [supportType, setSupportType] = useState<null | string>(null);
  const [supportLabel, setSupportLabel] = useState('');
  const [isSupportDetailsModalOpen, setIsSupportDetailsModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  const [searchError, setSearchError] = useState<string | null>(null);
  const [showQrReader, setShowQrReader] = useState(false);

  const projectSelected = localStorage.getItem('project');
  const dispatch = useDispatch();

  function handleChangeSupportType(type: string) {
    setSupportType(type);
    setSupportLabel('');
  }

  function handleSearchSupport(label?: string) {
    setLoading(true);
    get('/nfctags/label/' + (supportLabel ? supportLabel : label), {
      project_id: projectSelected,
    })
      .then((response) => {
        setCurrentCard(response.data);
        setIsSupportDetailsModalOpen(true);
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(setAuthenticated(false));
        }
        if (error.status === 404) {
          setSearchError('Support inconnue');
          setTimeout(() => {
            setSearchError(null);
          }, 2000);
        }
        if (error.status === 429) {
          setSearchError('Trop de tentative, veuillez patienter');
          setTimeout(() => {
            setSearchError(null);
          }, 2000);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    setIsSupportDetailsModalOpen(true);
  }

  function handleCloseSupportDetailsModal() {
    setIsSupportDetailsModalOpen(false);
    setSupportLabel('');
    setSupportType(null);
    setCurrentCard(null);
  }

  function qrCodeSuccessCallback(url: string) {
    setShowQrReader(false);
    let code: any;
    if (url.indexOf('payconiq') !== -1) {
      const regex = /\/.*&R=(\d*).(.*)$/g;
      const xp = regex.exec(url);
      if (xp) {
        code = xp[2];
      }
    } else {
      code = url;
    }
    setSupportLabel(code);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle class="ion-text-center">NFC</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <div style={{ height: '100%' }}>
          <div className="research-card-container">
            <div className="support-choice">
              <h2 className="support-choice-title">
                Choisir le type de support
              </h2>
              <div
                className={
                  'bracelet-container' +
                  (supportType === 'bracelet' ? ' selected' : '')
                }
                onClick={() => handleChangeSupportType('bracelet')}
              >
                {showQrReader && supportType === 'bracelet' ? (
                  <QrScanner
                    onDecode={(result) => qrCodeSuccessCallback(result)}
                    onError={(error) => console.log(error?.message)}
                    constraints={{ facingMode: 'environment' }}
                  />
                ) : (
                  <>
                    <div className="bracelet-title">Bracelet</div>
                    <div className="bracelet-block">
                      <div className="bracelet-ribbon"></div>
                      <div className="bracelet-card">
                        <div className="bracelet-label">XXXX-XXXX</div>
                      </div>
                    </div>
                    {supportType === 'bracelet' && (
                      <div className={'enter-support-label-container'}>
                        <div className={'support-label-title'}>
                          Identifiant du bracelet
                        </div>
                        <div className={'input-label-container'}>
                          <input
                            value={supportLabel}
                            type="text"
                            className={'support-label'}
                            placeholder={'XXXX-XXXX'}
                            onChange={(e) => setSupportLabel(e.target.value)}
                          />
                          <IonIcon
                            icon={cameraOutline}
                            className={'scan_camera'}
                            onClick={() => setShowQrReader(true)}
                          />
                          {searchError && (
                            <div className={'support-label-error'}>
                              {searchError}
                            </div>
                          )}
                        </div>
                        <button
                          className={
                            'btn' + (supportLabel.length < 4 ? ' disabled' : '')
                          }
                          onClick={() => handleSearchSupport()}
                        >
                          Chercher le bracelet
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div
                className={
                  'card-container' + (supportType === 'card' ? ' selected' : '')
                }
                onClick={() => handleChangeSupportType('card')}
              >
                {showQrReader && supportType === 'card' ? (
                  <QrScanner
                    onDecode={(result) => qrCodeSuccessCallback(result)}
                    onError={(error) => console.log(error?.message)}
                    constraints={{ facingMode: 'environment' }}
                  />
                ) : (
                  <>
                    <div className="card-title">Carte</div>
                    <div className="card-block">
                      <div className="card"></div>
                      <div className="card-label">XXXX-XXXX-XXXX</div>
                    </div>
                    {supportType === 'card' && (
                      <div className={'enter-support-label-container'}>
                        <div className={'support-label-title'}>
                          Identifiant de la carte
                        </div>
                        <div className={'input-label-container'}>
                          <input
                            type="text"
                            className={'support-label'}
                            placeholder={'XXXX-XXXX-XXXX'}
                            onChange={(e) => setSupportLabel(e.target.value)}
                            value={supportLabel}
                          />
                          <IonIcon
                            icon={cameraOutline}
                            className={'scan_camera'}
                            onClick={() => setShowQrReader(true)}
                          />
                          {searchError && (
                            <div className={'support-label-error'}>
                              {searchError}
                            </div>
                          )}
                        </div>
                        <button
                          className={
                            'btn' + (supportLabel.length < 4 ? ' disabled' : '')
                          }
                          onClick={() => handleSearchSupport()}
                        >
                          Chercher la carte
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Loading isLoading={loading} />
        {currentCard && (
          <SupportDetailsModal
            isOpen={isSupportDetailsModalOpen}
            closeModal={handleCloseSupportDetailsModal}
            reload={(cardLabel) => handleSearchSupport(cardLabel)}
            currentCard={currentCard}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Nfc;
